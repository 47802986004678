import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Multiselect from "multiselect-react-dropdown";
import Pricing from './components/Pricing';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainnetType: [1],
      options: [
        { key: 'Ethereum (mainnet)', value: '1' },
        { key: 'Other EVMs (not mainnet)', value: '2' },
        
        // Add more options as needed
      ],
      serviceSelectOption: [],
      selectedValues: [{ key: 'Ethereum (mainnet)', value: '1' }],
    }; 
  }
  
  handleSelect = (selectedList) => {
  
    let mainnetType = selectedList[0].value;
    this.setState({ mainnetType });
    
  };
  
  render() {
    const { mainnetType, options, selectedValues } = this.state;
    
    return (
      <Container className='withe-border-shadow'>
        <Row className='justify-content-md-center'>
            <Col sm="auto" className='main-content'>
              
              <Multiselect
                options={options}
                displayValue="key"
                onSelect={this.handleSelect}
                onRemove={this.handleSelect}
                selectedValues={selectedValues}
                showCheckbox="true"
                closeIcon="circle"
                keepSearchTerm="false"
                avoidHighlightFirstOption="true"
                showArrow="true"
                singleSelect="true"
              />
              <hr />
              <Pricing mainnetType={mainnetType}/>
              <div className='bottom-text'>
                  <p>
                  1 Monthly Action = a claim, a stake deposit or unstake withdrawal.
                  </p>
                </div>
            </Col>
        </Row>
      </Container>  
    );
  }
}

export default App;