import React, { useState } from "react";


function Pricing(data) {

    const [swapEstimated, setSwapEstimated] = useState(0);
    const [swapMonthly, setSwapMonthly] = useState(0);
    const [swapYearly, setSwapYearly] = useState(0);
    const [zapEstimated, setZapEstimated] = useState();
    const [zapMonthly, setZapMonthly] = useState(0);
    const [zapYearly, setZapYearly] = useState(0);
    const [userEstimated, setUserEstimated] = useState(0);
    // this state is for naminnet
    const [userMonthly, setUserMonthly] = useState(0);
    const [userYearly, setUserYearly] = useState(0);
    // this state is for not mainnet
    const [userMonthlyOther, setUserMonthlyOther] = useState(0);
    const [userYearlyOther, setUserYearlyOther] = useState(0);
    

    
    var numFormat = function (value) {
        return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };

    const handelSwapEstimated = function(e){

        let input = (e.target.value) ? e.target.value : '0';
        let value = (!isNaN(parseFloat(input.replace(/,/g, '')))) ? parseFloat(input.replace(/,/g, '')) : '0'
        
        setSwapEstimated(value)
        setSwapMonthly(Math.round((value * 0.008) * 100 ) / 100)
        setSwapYearly(Math.round((value * 0.096) * 100 ) / 100)
        
    }

    const handleZapEstimated = function(e){
        
        let input = (e.target.value) ? e.target.value : '0';
        let value = (!isNaN(parseFloat(input.replace(/,/g, '')))) ? parseFloat(input.replace(/,/g, '')) : '0'

        setZapEstimated(value)
        setZapMonthly(Math.round((value * 0.007) * 100 ) / 100)
        setZapYearly(Math.round((value * 0.084) * 100 ) / 100)
    }

    const handleUserAction = function(e){
        
        let input = (e.target.value) ? e.target.value : '0';
        let value = (!isNaN(parseFloat(input.replace(/,/g, '')))) ? parseFloat(input.replace(/,/g, '')) : '0'

        setUserEstimated(value)
        // set data for mainnet
        setUserMonthly(Math.round((value * 3 * 0.05) * 100 ) / 100)
        setUserYearly(Math.round((value * 3 * 0.05 * 12) * 100 ) / 100)
        // set data for not mainet 
        setUserMonthlyOther(Math.round((value  * 0.05) * 100 ) / 100)
        setUserYearlyOther(Math.round((value  * 0.05 * 12) * 100 ) / 100)
        
    }

    let totalMonthly = swapMonthly + zapMonthly + ((parseInt(data.mainnetType) === 1)? userMonthly: userMonthlyOther)
    let totalYearly = swapYearly + zapYearly + ((parseInt(data.mainnetType) === 1)? userYearly: userYearlyOther)

    return (
      <>
        <table className="table table-bordered">
            <tbody>
            {/* swap mudal */}
                <tr className="td-title">
                    <th colSpan="2">
                        Swap module
                    </th>
                </tr>
                <tr>
                    <td>
                        Estimated Monthly Volume 
                    </td>
                    <td>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon1">$</span>
                            <input type="text" className="form-control"  value={(swapEstimated) ? swapEstimated.toLocaleString(): ''} onChange={(e) => handelSwapEstimated(e)} placeholder="0" />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Monthly Revenue</td>
                    <td>
                        {/* 0.8 % */}
                        ${numFormat(swapMonthly)}
                    </td>
                </tr>
                <tr>
                    <td>Yearly Revenue</td>
                    <td>
                        {/* 9.6 % */}
                        ${numFormat(swapYearly)}
                    </td>
                </tr>
                <br />
                {/* zap mudal */}
                <tr className="td-title">
                    <th colSpan="2">
                        Zap module
                    </th>
                </tr>
                <tr>
                    <td>
                        Estimated Monthly Volume 
                    </td>
                    <td>
                        <div className="input-group">
                            <span className="input-group-text" id="basic-addon1">$</span>
                            <input type="text" className="form-control"  value={(zapEstimated) ? zapEstimated.toLocaleString(): ''} onChange={(e) => handleZapEstimated(e)} placeholder="0" />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Monthly Revenue</td>
                    <td>
                        ${numFormat(zapMonthly)}
                    </td>
                </tr>
                <tr>
                    <td>Yearly Revenue</td>
                    <td>
                        ${numFormat(zapYearly)}
                    </td>
                </tr>
                <br />
                {/* user action */}
                <tr className="td-title">
                    <th colSpan="2">
                        User action
                    </th>
                </tr>
                <tr>
                    <td>
                        Estimated Monthly Actions of all users on dApp<span className='sub-text'>1</span>
                    </td>
                    <td>
                        <div className="input-group">
                            
                            <input type="text" className="form-control"  value={(userEstimated) ? userEstimated.toLocaleString(): ''} onChange={(e) => handleUserAction(e)} placeholder="0" />

                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Monthly Revenue</td>
                    <td>
                        ${numFormat((parseInt(data.mainnetType) === 1)? userMonthly : userMonthlyOther)}
                    </td>
                </tr>
                <tr>
                    <td>Yearly Revenue</td>
                    <td>
                        ${numFormat((parseInt(data.mainnetType) === 1)? userYearly : userYearlyOther)}
                    </td>
                </tr>
                <br />
                {/* total  */}
                <tr>
                    <th>Total Monthly Revenue</th>
                    <th>
                        ${numFormat(totalMonthly)}
                    </th>
                </tr>
                <tr>
                    <th>Total Yearly Revenue</th>
                    <th>
                        ${numFormat(totalYearly)}
                    </th>
                </tr>
            </tbody>
        </table>
      </>
    );
  }
  
  export default Pricing;
  